import React, { Component } from 'react';
import { Form, Button, Row, Col, Accordion } from 'react-bootstrap';
//import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import moment from 'moment';

class Counter extends Component {

    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    }

    componentDidMount() {

        var timeTillDate = "01 03 2024, 11:59 pm";
        var timeFormat = "MM DD YYYY, h:mm a";

        var curr = moment().format("MM DD YYYY, h:mm a");
        var then = "01 03 2024, 11:59 pm";
        var final = then - curr;

        console.log('calc', final);

        this.interval = setInterval(() => {
           // const { timeTillDate, timeFormat } = this.props;
            const then = moment(timeTillDate, timeFormat);
            const now = moment();
            const countdown = moment(then - now);
            const days = countdown.format('D');
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');

            this.setState({ days, hours, minutes, seconds });
        }, 1000);
        //    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        console.log('now', curr)

    }

    componentWillUnmount() {
       // if (this.interval) {
       //     clearInterval(this.interval);
      //  }

        clearInterval(this.interval);
    }


    constructor(props) {
        super(props);
        this.state = {
            currDay: new Date().getDay(),
            currHour: new Date().getHours(),
            currMin: new Date().getMinutes(),
            currSec: new Date().getSeconds(),


            //  countDownDate: moment().add(12960000, 'seconds')
            openingDate: moment().format('LTS'),


        }
    }


    render() {
        let dates = '1231312';

        const { days, hours, minutes, seconds } = this.state;
       

        if (!seconds) {
            return null;
        }

        //Use currentTime and startTime to build the following 4 variables.
        //let days = '2', hours = '3', minutes = '20', seconds = '30';
        return (
            <Container className="">
                <Row>
                    <Col />
                    <Col><div>
                        <h1 className="ml-2">{days}: </h1>
                        <h2>Days</h2>
                    </div>
                    </Col>
                    <Col><div>
                        <h1 className="ml-2">{hours}: </h1>
                        <h2>Hours</h2>
                    </div>
                    </Col>
                    <Col> <div>
                        <h1 className="ml-2">{minutes}: </h1>
                        <h2>Minutes</h2>
                    </div>
                    </Col>
                    <Col><div>
                        <h1 className="ml-2">{seconds}</h1>
                        <h2>Seconds</h2>
                    </div></Col>
                    <Col />
                </Row>



            </Container>
        )
    }


}

export default Counter;
