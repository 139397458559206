import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import ScheduleEventHeader from './ScheduleEventHeader';
import ScheduleEventBody from './ScheduleEventBody';

class ScheduleEvent extends Component {
    render() {
        return (
            <Accordion.Item eventKey={this.props.eventKey}>
                <Accordion.Header>
                    <ScheduleEventHeader date={this.props.event.date} eventName={this.props.event.eventName} />
                </Accordion.Header>
                <Accordion.Body>
                    <ScheduleEventBody
                        startTime={this.props.event.startTime}
                        endTime={this.props.event.endTime}
                        location={this.props.event.location}
                        //audience={this.props.event.audience}
                        sponsor={this.props.event.sponsor}
                        link={this.props.event.link}
                        description={this.props.event.description} />
                </Accordion.Body>
            </Accordion.Item>
        )
    }
}

export default ScheduleEvent;
