import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import UICicon from './../../img/icons/favicon.ico';

class LayoutHeader extends Component {
    render() {
        return (
            <Navbar bg='light' expand='lg' className="wow-container">
                <Container style={{ height: 62 }}>
                    <Navbar.Brand className="wow-header-text" href='#'>
                        <img src={UICicon} style={{ width: 40 }} />
                        &nbsp;Weeks of Welcome
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav.Link href="#schedule" className="wow-header-text" title="Event Schedule">Event Schedule</Nav.Link>
                        <Nav.Link href="#sponsors" className="wow-header-text" title="Sponsors">Sponsors</Nav.Link>
                        <Nav.Link href="https://www.facebook.com/groups/UICNSFP" className="wow-header-text"
                            title="Follow Us on Facebook"><FaFacebookF /></Nav.Link>
                        <Nav.Link href="https://www.instagram.com/UICOrientation/" className="wow-header-text"
                            title="Instagram"><FaInstagram /></Nav.Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default LayoutHeader;
