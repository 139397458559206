import React, { Component } from 'react';
import { Form, Button, Row, Col, Accordion } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ScheduleEvent from './ScheduleEvent';

class Schedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departments: [],
            departmentSelected: 'None',
            audiences: [],
            audiencesSelected: [],
            events: [],
            locations: [],
            address: [],
            locationSelected: 'None'
        };
        this.getEvents();
    }

    getEvents = () => {
        var lowerBound = new Date(2024, 7, 22);
        var upperBound = new Date(2024, 8, 22);

        console.log(lowerBound.toJSON());
        console.log(upperBound.toJSON());
        fetch(`/api/Events?startDate=${upperBound.toJSON()}&endDate=${lowerBound.toJSON()}`).then(resp => resp.json()).then(data => {
            let events = [], evDepts = [], evAuds = [], evLocs = [];
            let evDeptsMap = new Map();
            let evAudMap = new Map();
            let evLocsMap = new Map();
            if (Array.isArray(data)) {
                data.forEach(event => {
                    let startDate = new Date(event.eventDate);
                    let endDate = new Date(event.eventEndDate);
                    for (let date = startDate; date.valueOf() <= endDate.valueOf(); date.setDate(date.getDate() + 1)) {
                        if (date.valueOf() < lowerBound.valueOf() || date.valueOf() >= upperBound.valueOf()) continue;
                        evDeptsMap.set(event.groupId, event.group);
                        evAudMap.set(event.eventTypeId, event.eventType);
                        evLocsMap.set(event.locationType, event.locationType, event.eventLocation);
                        let id = event.eventUid + '-' + date.toDateString();
                        events.push({
                            id: id,
                            key: id,
                            sponsor: event.group,
                            date: new Date(date.valueOf()),
                            startTime: event.eventTime,
                            endTime: event.eventEndTime,
                            description: event.description,
                            link: event.eventLink,
                            audience: event.eventType,
                            audienceID: event.eventTypeId,
                            location: event.locationType + ', ' + event.eventLocation,
                            locationID: event.locationType,
                            address: event.eventLocation,
                            eventName: event.title,
                            departmentID: event.groupId
                        });
                    }
                })
            }
            else {
                let startDate = new Date(data.eventDate);
                let endDate = new Date(data.eventEndDate);
                for (let date = startDate; date.valueOf() <= endDate.valueOf(); date.setDate(date.getDate() + 1)) {
                    if (date.valueOf() < lowerBound.valueOf() || date.valueOf() >= upperBound.valueOf()) continue;
                    evDeptsMap.set(data.groupId, data.group);
                    evAudMap.set(data.eventTypeId, data.eventType);
                    evLocsMap.set(data.locationType, data.locationType, data.eventLocation);
                    let id = data.eventUid + '-' + date.toDateString();
                    events.push({
                        id: id,
                        key: id,
                        sponsor: data.group,
                        date: new Date(date.valueOf()),
                        startTime: data.eventTime,
                        endTime: data.eventEndTime,
                        description: data.description,
                        link: data.eventLink,
                        audience: data.eventType,
                        audienceID: data.eventTypeId,
                        location: data.locationType + ', ' + data.eventLocation,
                        locationID: data.locationType,
                        address: data.eventLocation,
                        eventName: data.title,
                        departmentID: data.groupId
                    });
                }
            }
            for (const [key, value] of evDeptsMap.entries()) {
                evDepts.push({ id: key, name: value });
            }
            for (const [key, value] of evAudMap.entries()) {
                evAuds.push({ id: key, name: value });
            }
            for (const [key, value] of evLocsMap.entries()) {
                evLocs.push({ id: key, name: value });
            }

            events.sort((a, b) => { return a.date.valueOf() - b.date.valueOf(); });
            evDepts.sort((a, b) => { return a.name.localeCompare(b.name); });
            evAuds.sort((a, b) => { return a.name.localeCompare(b.name); });
            evLocs.sort((a, b) => { return a.name.localeCompare(b.name); });
            console.log(events)
            console.log(evDepts)
            console.log(evAuds)
            console.log(evLocs)
            this.setState({ events: events, departments: evDepts, audiences: evAuds, locations: evLocs });
        })
    }

    render() {
        let eventsLeft = [];
        let eventsRight = [];
        let filteredEvents = this.state.events;
        filteredEvents = filteredEvents.filter(event => {
            return this.state.departmentSelected === 'None' || this.state.departmentSelected === event.departmentID.toString()
        })
        filteredEvents = filteredEvents.filter(event => {
            return this.state.locationSelected === 'None' || this.state.locationSelected === event.locationID.toString()
        })
        
        filteredEvents = filteredEvents.filter(event => {
            return this.state.audiencesSelected.length === 0 ||
                this.state.audiencesSelected.findIndex(i => i === event.audienceID.toString()) !== -1;
        })
        filteredEvents.forEach((event, index) => {
            if (index % 2 === 0) eventsLeft.push(event);
            else eventsRight.push(event);
        })
        return (
            <Container className="wow-container wow-body-container bg-light">
                <header>
                    <h3>Weeks of Welcome <span className="uic-text-color">Schedule</span></h3>
                    <p>Get involved, discover your school spirit, and take the next step toward lifelong memories!</p>
                </header>
                <div>
                    <Form>
                        {/*<Form.Label>Filter by audience</Form.Label>
                        <Form.Control as="select" multiple
                            onChange={e => this.setState({
                                audiencesSelected: Object.values(e.target).filter(o => o.selected).map(o => o.value)
                            })}
                            value={this.state.audiencesSelected} >
                            {this.state.audiences.map(audience =>
                                <option value={audience.id} key={audience.id}>{audience.name}</option>
                            )}
                        </Form.Control>*/}
                        <Form.Label>Filter by organization</Form.Label>
                        <Form.Control as="select"
                            onChange={e => this.setState({ departmentSelected: e.target.value })}
                            value={this.state.departmentSelected}
                        >
                            <option value="None">None</option>
                            {this.state.departments.map(department =>
                                <option value={department.id} key={department.id}>{department.name}</option>
                            )}
                        </Form.Control>
                        <Form.Label>Filter by location</Form.Label>
                        <Form.Control as="select"
                            onChange={e => this.setState({ locationSelected: e.target.value })}
                            value={this.state.locationSelected}
                        >
                            <option value="None">None</option>
                            {this.state.locations.map(location =>
                                <option value={location.id} key={location.id}>{location.name}</option>
                            )}
                        </Form.Control>
                        <hr />
                        <Accordion>
                            <Row>
                                <Col>
                                    {eventsLeft.map(event =>
                                        <ScheduleEvent key={event.id} eventKey={event.id} event={event} />
                                    )}
                                </Col>
                                <Col>
                                    {eventsRight.map(event =>
                                        <ScheduleEvent key={event.id} eventKey={event.id} event={event} />
                                    )}
                                </Col>
                            </Row>
                        </Accordion>
                    </Form>
                </div>
            </Container>
        )
    }
}

export default Schedule;
