import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import WOWfooter from './../../img/footerWOW.png';

class LayoutFooter extends Component {
    render() {
        return (
            <Container className="uic-background-color">
                <div style={{ padding: "1em 2em" }}>
                    <Row>
                        <Col sm={6}>
                            <div style={{ paddingTop: 10, paddingBottom: 10, color: "white", textAlign: "center" }}>
                                <div>
                                    <a href='https://www.sa.uic.edu' style={{ color: "white" }}><b>Student Affairs</b></a>
                                    &nbsp;&bull;&nbsp;
                                    <a href='https://www.uic.edu' style={{ color: "white" }}><b>UIC</b></a>
                                    &nbsp;&bull;&nbsp;
                                    <a href='mailto:saweb@uic.edu' style={{color: "white"}}><b>Webmaster</b></a></div>
                                <div>THE BOARD OF TRUSTEES OF THE UNIVERSITY OF ILLINOIS &copy; {new Date().getFullYear()}</div>
                                <div>All Rights Reserved </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <img style={{ width: "100%" }} className="img-responsive" src={WOWfooter} />
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default LayoutFooter;
