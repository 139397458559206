import React, { Component } from 'react';
import LayoutHeader from './LayoutHeader';
import LayoutFooter from './LayoutFooter';
import LayoutAdvert from './LayoutAdvert';

class Layout extends Component {
    render() {
        return (
            <div className="background-image">
                <LayoutHeader />
                <LayoutAdvert />
                {this.props.children}
                <LayoutFooter />
            </div>
        )
    }
}

export default Layout;
