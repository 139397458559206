import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Counter from '../BodyItems/Counter';
import WOWlogo from './../../img/WOWlogo.png';

class LayoutAdvert extends Component {
    render() {
        return (
            <Container style={{ textAlign: "center" }}>
                <img src={WOWlogo} />
                {/*
                <Counter />
                */}
                <h2>August 23, 2024 - September 21, 2024</h2>
            </Container>
        )
    }
}

export default LayoutAdvert;
