import React from 'react';
import Layout from './components/LayoutItems/Layout';
import About from './components/BodyItems/About';
import Schedule from './components/BodyItems/Schedule/Schedule';

export default () => (
    <Layout>
        <About />
        <Schedule />
    </Layout>
);
