import React, { Component } from 'react';
import { Table, Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

class ScheduleEventBody extends Component {

    constructor(props) {
        super(props);
        this.constructTime = this.constructTime.bind(this);
    }

    constructTime = (time) => {
        let hour = (time.getHours() % 12).toString();
        if (hour == '0') hour = '12';
        return {
            hour: hour,
            minute: time.getMinutes().toString().padStart(2, '0'),
            isAM: time.getHours() / 12 < 1
        };
    }

    render() {
        let startTime = this.props.startTime;
        let endTime = this.props.endTime;
        return (
            <Container>
                <Table size="sm">
                    <tbody>
                        <tr>
                            <td>Time</td>
                            <td>
                                {startTime}
                                &nbsp;-&nbsp;
                                {endTime}
                            </td>
                        </tr>
                        <tr>
                            <td>Location</td>
                            <td>{this.props.location}</td>
                        </tr>
           
                        {/*<tr>
                            <td>Audience</td>
                            <td>{this.props.audience}</td>
                        </tr>*/}
                        <tr>
                            <td>Sponsored By</td>
                            <td>{this.props.sponsor}</td>
                        </tr>

                        {this.props.link !== '' &&
                            <tr>
                                <td>Event Link</td>
                            <td><a href={this.props.link}>{this.props.link}</a></td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <Card>
                    <Card.Header>Event Description</Card.Header>
                    <Card.Body>
                        <Card.Text>{this.props.description}</Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default ScheduleEventBody;
