import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

class About extends Component {
    render() {
        return (
            <Container className="wow-container wow-body-container bg-light">
                <h3>About <span className="uic-text-color">Weeks of Welcome</span></h3>
                <p>Weeks of Welcome (WOW) is a series of events welcoming students to campus while encouraging them to begin their journey toward getting involved, discovering school spirit and taking steps toward lifelong memories. UIC Welcomes all new and returning students to campus! Come celebrate the beginning of a new semester with us! Check below for a list of events.</p>
            </Container>
        )
    }
}

export default About;
