import React, { Component } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';

class ScheduleEventHeader extends Component {
    render() {
        let month = (this.props.date.getMonth() + 1).toString().padStart(2, '0');
        let day = (this.props.date.getDate()).toString().padStart(2, '0');
        
        return (
            <Row>
                <Col md='auto'><h4><Badge bg="danger">{month}.{day}</Badge></h4></Col>
                <Col><h5>{this.props.eventName}</h5></Col>
            </Row>
        )
    }
}

export default ScheduleEventHeader;
